<template>
  <div class="footer-root">
    <div class="footer-nav">
      <div class="navigation me-auto">
        <NuxtLink
          qid="cFooter-1"
          :to="addQueryParams('/terms', { queryParams, allowedQueryParams })"
          class="footer-link"
          aria-label="Terms of use"
          >{{ $t('_COMMON_ACROSS_MODULES.FOOTER.TERMS_OF_USE') }}</NuxtLink
        >
        <NuxtLink
          qid="cFooter-2"
          :to="addQueryParams('/privacy', { queryParams, allowedQueryParams })"
          class="footer-link"
          aria-label="Privacy notice"
          >{{ $t('_COMMON_ACROSS_MODULES.FOOTER.PRIVACY_POLICY') }}
        </NuxtLink>
        <NuxtLink
          qid="cFooter-3"
          :to="
            addQueryParams('/accessibility', {
              queryParams,
              allowedQueryParams
            })
          "
          class="footer-link"
          aria-label="Accessibility"
          >{{ $t('_COMMON_ACROSS_MODULES.FOOTER.ACCESSIBILITY') }}
        </NuxtLink>
        <NuxtLink
          external
          :prefetch="false"
          qid="cFooter-5"
          class="footer-link"
          target="_blank"
          to="http://www.cambridge.org/english"
          aria-label="Our approach"
        >
          {{ $t('_COMMON_ACROSS_MODULES.FOOTER.OUR_APPROACH') }}
        </NuxtLink>
        <NuxtLink
          external
          :prefetch="false"
          qid="cFooter-4"
          class="footer-link"
          target="_blank"
          to="https://surveys.eu.customergauge.com/start.php?company=UIQC&form_id=11"
          aria-label="Site feedback"
        >
          {{ $t('_COMMON_ACROSS_MODULES.FOOTER.SITE_FEEDBACK') }}
        </NuxtLink>
        <NuxtLink
          v-if="!userData || (userData && userData.launchType !== 'lti')"
          external
          :prefetch="false"
          qid="cFooter-9"
          class="footer-link"
          :to="getHelpPagePath(runtimeConfig.app.appEnv)"
          target="_blank"
          aria-label="FAQs"
        >
          {{ $t('_COMMON_ACROSS_MODULES.FOOTER.FAQ') }}
        </NuxtLink>
      </div>
      <div class="footer-content">
        <NuxtLink
          v-if="
            !userData ||
            (userData &&
              userData.role != 'student' &&
              userData.role != 'parent' &&
              userData.launchType !== 'lti')
          "
          qid="cFooter-9"
          class="insti-btn"
          :to="
            addQueryParams('/institution-request', {
              queryParams,
              allowedQueryParams
            })
          "
          aria-label="Cambridge One for schools"
        >
          {{ $t('_COMMON_ACROSS_MODULES.FOOTER.INSTITUTION_REQUEST') }}
        </NuxtLink>
        <p class="copyright mb-0">
          &copy;
          {{ $t('_COMMON_ACROSS_MODULES.FOOTER.CAMBRDIGE_UNIVERSITY_PRESS') }}
          {{ getCurrentYear() }}
        </p>
      </div>
    </div>
    <div class="ms-auto d-flex flex-column flex-xl-row align-self-start align-items-center">
      <NuxtLink v-if="!userData || (userData && userData.launchType !== 'lti')" external :prefetch="false"
        qid="cFooter-6" :to="getHelpPagePath(runtimeConfig.app.appEnv)" target="_blank"
        class="btn btn-main-1 btn-help" aria-label="Help">
        <ClientOnly><nuxt-icon loading="lazy" name="fa6-regular.circle-question" class="fa-circle-question"
            aria-hidden="true" /></ClientOnly>
        {{ $t('_COMMON_ACROSS_MODULES.FOOTER.HELP') }}
      </NuxtLink>
      <div class="dropdown" v-if="userData && (userData.role !='teacher' || userData.launchType !='lti') &&
        (!isTeacherViewPossible || (adminSelectedViewVal=='admin') || (userData.role=='superadmin'
        && !adminSelectedViewVal))">
        <div data-bs-toggle="dropdown" class="language-dropdown lang-dropup d-flex align-items-center" tabindex="0">
          <ClientOnly><nuxt-icon loading="lazy" name="nemo.globe" class="nemo-globe-footer" aria-hidden="true" />
          </ClientOnly>
          <span class="lang-dropdown-text font-xs font-weight-normal">
            {{ getLocaleName(locale) }}
          </span>
        </div>

        <div aria-labelledby="dropdownMenuLink" class="dropdown-menu">
          <div v-for="localee in locales" :key="localee.code" :class="{ 'selected-item': localee.code == locale }"
            class="text-center dropdown-item" @click="changeLang(localee.code)">
            {{ localee.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();
const { getHelpPagePath } = useHelpConfig();
const { getUserData , checkUserAuthenticatedCache } = useOnboardingAuth();
const { addQueryParams } = useHttpHelper();
const queryParams = route.query;
const runtimeConfig = useRuntimeConfig();
const allowedQueryParams = runtimeConfig.public.ielts.allowedQueryParams;
const userData = ref(null);
const { locale, locales, setLocale } = useI18n();
const { showOverlayLoader, hideLoader } = useLoaderService();
const { lang:language , adminSelectedView } = useLocalStorage();
let isTeacherViewPossible = false;
let adminSelectedViewVal;

onMounted(async () => {
  checkUserAuthenticatedCache().then(()=>{
    userData.value = getUserData();
    if(userData.value){
      isTeacherViewPossible= (['teacher', 'admin', 'superadmin'].indexOf(userData.value.role) > -1);
      adminSelectedViewVal = adminSelectedView + userData.value.extUserId;
      adminSelectedViewVal = window.localStorage.getItem(adminSelectedViewVal);
    }
  })
});
const getCurrentYear = () => {
  return (new Date().getFullYear());
}

const getLocaleName = (localeCode) => {
 switch(localeCode){
  case 'En':
    return 'English'
  case 'Es':
    return 'Español'
  default:
    return 'English'
 }
}
const changeLang = async (lang) => {
  showOverlayLoader();
  window.localStorage.setItem(language,lang.toLowerCase());
  await setLocale(lang);
  hideLoader();
  if (runtimeConfig.public.gigyaScreens[route.path]) {
    window.location.reload();
  }
};
</script>

<style lang="scss" scoped>
.footer-root {
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  background-color: var(--cup-general-color-1);
  color: var(--cup-general-color-4);
  box-shadow: 1px -1px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 20px 16px 10px;

  @include media-breakpoint-up(md) {
    padding: 10px 24px;
  }

  @include media-breakpoint-up(xl) {
    padding: 10px 32px;
  }

  .footer-nav {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      align-items: center;
    }

    .navigation {
      display: flex;
      margin-right: auto;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      a {
        color: var(--cup-general-color-4);
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        margin: 4px 0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
          border-right: solid 1px var(--cup-general-color-3);
          padding: 4px 8px;
          margin: 0;

          &:first-child {
            padding-left: 0;
            margin-left: 0;
          }
        }

        &:last-child {
          border-right: 0 none;
        }
      }
    }

    .footer-content {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: column;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }

      .copyright {
        font-size: 12px;
        line-height: 16px;
        margin-top: 25px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-right: 15px;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 0;
          margin-right: 15px;
        }
      }

      .insti-btn {
        color: var(--cup-general-color-5);
        background-color: #f5f5f5;
        font-size: 12px;
        font-weight: 800;
        line-height: 14px;
        margin-right: 24px;
        padding: 10px 5px;
        margin-top: 10px;
        text-decoration: none;

        @include media-breakpoint-up(md) {
          margin-top: 0px;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 0px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .btn-help {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 15px;

    .fa-circle-question {
      font-size: 16px;
      margin-right: 7px;
    }
  }
  .nemo-globe-footer{
    color: var(--cup-main-color-1);
  }
  .lang-dropup{
    cursor: pointer;
    margin-top: 20px;
    @include media-breakpoint-up(xl){
      flex-shrink: 0;
      align-self: center;
      margin-top: 0;
      margin-left: 36px;
    }
  }
  .lang-dropdown-text{
    color: var(--cup-main-color-1);
    font-size: 12px;
    line-height: 14px;
  }
  .dropdown-menu {
    border-radius: 2px;
    min-width: 85px;
    padding: 8px;
    border: 0 none;
    box-shadow: 0 2px 24px -6px #00000026, 0 2px 20px #00000040;
    transform: translate3d(20.833px, -25.8333px, 0px)!important;

    @include media-breakpoint-down(xl){
      transform: translate3d(-15.167px, -25.8333px, 0px) !important
    }
    .dropdown-item {
      cursor: pointer;
      color: var(--cup-general-color-5);
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      padding: 8px 0;
      text-align: center;
      &:hover {
          background-color: transparent;
          color: var(--cup-main-color-1);
      }   
      &:focus {
            background-color: transparent;
      }
      &.active, 
      &:active {
          background-color: transparent;
          color: var(--cup-main-color-1);
      }
    }
    &:after, 
    &:before {
        top: 100%;
        left: 70%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(55, 55, 55, 0);
        border-width: 10px;
        margin-left: -10px;
    }
      &:before {
        border-color: rgba(55, 55, 55, 0);
        border-top-color: var(--cup-general-color-1);
        border-width: 8px;
        margin-left: -8px;
    }
  }
}
</style>
